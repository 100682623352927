/**
 * 処理
*****************************************************/
window.onload = function () {
  
}

window.addEventListener("load", function () {
  pcHeaderMenuClose();
  setNewsDetailBred();
});

document.addEventListener("DOMContentLoaded", function () {
  setNewsDetailBred(); //ニュース詳細ページパンくず
	setBreadcrumb(); //パンくず表示用のJS
  // headerScroll();
  anchorSmoothScroll();
  pcHeaderHover();
  spHeaderDrawer();
  topMvScroll();
  clickModal();
  inqueryValidate();
  inqeryIsError();
  clickMovieStop();
  newsCategorySelect();
  searchYear();
  setNewsMoreBtn();
  clickNewsMoreBtn();
  setRecruitSlider();
});

window.addEventListener("resize", function () {

});


/**
* 関数
*****************************************************/

// 配列に変換
function convertArray(node) {
	const convertResult = Array.prototype.slice.call(node)
	return convertResult;
}

// pcヘッダーホバーメニュー処理
function pcHeaderHover() {
  $('.js-gnavi_lower').mouseover(function() {
    $(this).find('.js-nomal_hover').stop(true).fadeIn();
    $(this).parents('.header').find('.over-ray').stop(true).fadeIn();
  });
  $('.js-gnavi_lower').mouseout(function() {
    $(this).find('.js-nomal_hover').stop(true).fadeOut();
    $(this).parents('.header').find('.over-ray').stop(true).fadeOut();
  });
}

// pcヘッダーページロード時処理
function pcHeaderMenuClose() {
  $('.js-gnavi_lower').find('.js-nomal_hover').removeClass('active');
  $('.js-gnavi_lower').parents('.header').find('.over-ray').removeClass('active');
}

// spヘッダー開閉処理
function spHeaderDrawer() {
  $('.js-box').on('click', function() {
    if ($(this).next('.js-drawer').css("display")!=="block") {
      $(this).addClass('active');
      $(this).next('.js-drawer').stop().slideDown("300");
      $(this).parents('.header').addClass('sp-active');
      $('.js-drawer').scrollTop(0);
    } else {
      $(this).removeClass('active');
      $(this).next('.js-drawer').stop().slideUp("300");
      $(this).parents('.header').removeClass('sp-active');
    }
  });
}

// mvスクロールボタンのスクロール処理
function topMvScroll() {
  $(window).on('load resize',function(){
    //ウィンドウの高さを取得する
    var targetY = $(window).height();
    
    //スクロールをクリックするとウィンドウの高さ分、下にスクロールする
    $('.js-mv-scroll').on('click',function(){
      $("html, body").stop().animate({scrollTop: targetY - 40}, 500, 'swing');
      return false;
    });
  });
}

function setNewsDetailBred() {
  var pageBredCrumb = $('.js-ttl-bredcrumb').text();
  $("meta[value ='news-detail']").attr('content',pageBredCrumb);
}

// パンくず表示
function setBreadcrumb() {
	if (document.querySelector('head').querySelector('[name="breadcrumb"]') == null) { return false }
	var count = document.querySelector('head').querySelector('[name="breadcrumb"]').content;
	var breadcrumb = document.getElementById('breadcrumb');

	// 元のパンくずのaタグ空にする
	var breadcrumbLink = breadcrumb.querySelectorAll('nav a');
	var breadcrumbLinkArr = convertArray(breadcrumbLink)
	breadcrumbLinkArr.forEach(function(e) {
		e.remove();
	})
	
	// meta要素内のパンくず取得、aタグに入れる
	for(var i = 0; i <= count; i++) {
		var content = document.querySelector('head').querySelector('[name="breadcrumb_' + i + '"]').content;
		var href =  document.querySelector('head').querySelector('[name="breadcrumb_link_' + i + '"]').content;

		var nav = breadcrumb.querySelector('nav');
		var newLink = document.createElement("a");
		var newLinkContent = document.createTextNode(content);
		newLink.appendChild(newLinkContent);
		newLink.setAttribute('href', href);
		nav.appendChild(newLink)

		if(i == count) {
			newLink.setAttribute('class', 'current');
		}
	}
}

function anchorSmoothScroll() {
  // アンカーリンクスムーススクロール
  $('a[href^="#"]').click(function(){
    // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
    var adjustPc = 0;
    var adjustSp = 0;
    // スクロールの速度（ミリ秒）
    var speed = 400;
    // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
    var href= $(this).attr("href");
    // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
    var positionPc = target.offset().top + adjustPc;
    var positionSp = target.offset().top + adjustSp;
    var breakpoint = 767;
    var lastWW = $(window).width();
    if (lastWW <= breakpoint) {
      // スムーススクロール linear（等速） or swing（変速）
      $('body,html').animate({scrollTop:positionSp}, speed, 'swing');
    } else {
      // スムーススクロール linear（等速） or swing（変速）
      $('body,html').animate({scrollTop:positionPc}, speed, 'swing');
    }
    $('window').on('resize', function() {
        var currentWW = $('window').width();
        if (lastWW <= breakpoint && breakpoint < currentWW) {
          $('body,html').animate({scrollTop:positionPc}, speed, 'swing');
        } else if (currentWW <= breakpoint && breakpoint < lastWW) {
          $('body,html').animate({scrollTop:positionSp}, speed, 'swing');
        }
        lastWW = currentWW;
    });
    return false;
  });
}

function clickModal() {
  var $clickItem = $('.js-modal');
  var $modalItem = $('.js-modal-contents');
  var $overRay = $('.bg');
  var $overRayOffice = $('.office-bg');

  $modalItem.hide();
  $overRay.hide();
  $overRayOffice.hide();
  $clickItem.on('click', function() {
    if ($(this).next('.js-modal-contents').css("display")!=="block") {
      $(this).next('.js-modal-contents').fadeIn(300);
      $(this).next('.js-modal-contents').find('.bg').fadeIn(300);
      $(this).next('.js-modal-contents').find('.office-bg').fadeIn(300);
      $(this).next('.js-modal-contents').find('.js-back-modal').fadeIn(300);
      $(this).next('.js-modal-contents').find('.js-back-modal-office').fadeIn(300);
    }
  });
  $('.js-back-modal').on('click', function() {
    if($(this).parents('.js-modal-contents').css("display")==="block") {
      $(this).parents('.js-modal-contents').fadeOut(300);
      $(this).fadeOut(300);
      // videoControl("stopVideo");
      var $playerWindow = $(this).parents('.js-modal-contents_wrap_back').next('.js-modal-contents_wrap_movie').find('.js-ytv-iframe')[0].contentWindow;
      // var $playerWindow = $('.js-ytv-iframe')[0].contentWindow;
      $playerWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    }
    return false;
  });
  $('.js-back-modal-office').on('click', function() {
    if($(this).parents('.js-modal-contents').css("display")==="block") {
      $(this).parents('.js-modal-contents').fadeOut(300);
      $(this).fadeOut(300);
    }
  });
  $('.bg').on('click', function() {
    if($(this).parents('.js-modal-contents').css("display")==="block") {
      $(this).parents('.js-modal-contents').fadeOut(300);
      $(this).fadeOut(300);
      var $playerWindow = $(this).prev('.js-modal-contents_wrap').find('.js-ytv-iframe')[0].contentWindow;
        $playerWindow.postMessage(
          '{"event":"command","func":"' + "stopVideo" + '","args":""}',
          "*"
        );
    }
    return false;
  });
  $('.office-bg').on('click', function() {
    if($(this).parents('.js-modal-contents').css("display")==="block") {
      $(this).parents('.js-modal-contents').fadeOut(300);
      $(this).fadeOut(300);
    }
  });
}

function clickMovieStop() {
  $('.js-back-modal').on('click', function () {
    videoControl("stopVideo");
    function videoControl(action) {
      // var $playerWindow = $(this).parents('.js-modal-contents_wrap_back').next('.js-modal-contents_wrap_movie').find('.js-ytv-iframe')[0].contentWindow;
      var $playerWindow = $('.js-ytv-iframe')[0].contentWindow;
      $playerWindow.postMessage(
        '{"event":"command","func":"' + action + '","args":""}',
        "*"
      );
    }
  });
}

function inqueryValidate() {
  $(".js-submit").on('click', function(){
    inquerySubmitReset();
    if ($("select[name='reference']").val() == '0' || $("input[name='name_kanji']").val() == '' || $("input[name='name_kana']").val() == '' || $("input[name='name_kana']").val() == '' || $("input[name='email']").val() == '' || $("input[name='tel']").val() == '' || $("textarea[name='content']").val() == '') {

      if ($("select[name='reference']").val() == '0') {
        $("select[name='reference']").addClass('is-error');
        $("select[name='reference']").siblings('.validation_txt').addClass('is-error');
      }
      if ($("input[name='name_kanji']").val() == '') {
        $("input[name='name_kanji']").addClass('is-error');
        $("input[name='name_kanji']").next('.validation_txt').addClass('is-error');
      }
      if ($("input[name='name_kana']").val() == '') {
        $("input[name='name_kana']").addClass('is-error');
        $("input[name='name_kana']").next('.validation_txt').addClass('is-error');
      }
      if ($("input[name='email']").val() == '' || !$("input[name='email']").val().match(/.+@.+\..+/)) {
        $("input[name='email']").addClass('is-error');
        $("input[name='email']").next('.validation_txt').addClass('is-error');
      }
      if ($("input[name='tel']").val() == '' || !$("input[name='tel']").val().match(/^[0-9０-９]+$/)) {
        $("input[name='tel']").addClass('is-error');
        $("input[name='tel']").next('.validation_txt').addClass('is-error');
      }
      if ($("textarea[name='content']").val() == '') {
        $("textarea[name='content']").addClass('is-error');
        $("textarea[name='content']").next('.validation_txt').addClass('is-error');
      }
      return false;
      
    } else {
      $(".js-submit").submit();
    }
  });
}

function inquerySubmitReset() {
  $("select[name='reference']").removeClass('is-error');
  $("select[name='reference']").siblings('.validation_txt').removeClass('is-error');
  $("input[name='name_kanji']").removeClass('is-error');
  $("input[name='name_kanji']").next('.validation_txt').removeClass('is-error');
  $("input[name='name_kana']").removeClass('is-error');
  $("input[name='name_kana']").next('.validation_txt').removeClass('is-error');
  $("input[name='email']").removeClass('is-error');
  $("input[name='email']").next('.validation_txt').removeClass('is-error');
  $("input[name='tel']").removeClass('is-error');
  $("input[name='tel']").next('.validation_txt').removeClass('is-error');
  $("textarea[name='content']").removeClass('is-error');
  $("textarea[name='content']").next('.validation_txt').removeClass('is-error');
}

function inqeryIsError() {
  $('.inquiry_inner_form_list_item').each(function() {
    if($(this).find('span').hasClass('error')) {
      $(this).find('.form-reference_select_list').addClass('is-error');
      $(this).find('.form-input').addClass('is-error');
      $(this).find('.form-txtarea').addClass('is-error');
    }
  });
}

function newsCategorySelect() {
  $('.js-news-select').on('click', function() {
    var isActive = $(this).hasClass('active');
    var $selectAll = $('.js-news-select[data-filter="all"]');
    // アクティブを持ってなく、すべて以外のタグをクリック時
    if (!isActive && $(this).data('filter') !== "all") {
      $(this).addClass('active');
      $selectAll.removeClass('active');
      search_filter();
      isHideRemove();
      isNewsItemNon();
      
    // アクティブを持っていて、すべて以外のタグをクリック時
    } else if (isActive && $(this).data('filter') !== "all") {
      $(this).removeClass('active');
      search_filter();
      isHideRemove();
      isNewsItemNon();
      
      // タグが全部アクティブを持っていないとき
      if ($('.js-news-select.active').length === 0) {
        $selectAll.addClass('active');
        searchAll();
        isNewsItemNon();
        if($('#search-year').val() === '') {
          setNewsMoreBtn();
        }
      }
      // すべて以外のタグのいずれかがアクティブを持っていて、すべてをクリックしたとき
    } else if (!isActive && $(this).data('filter') === "all") {
      $(this).siblings('.js-news-select.active').removeClass('active');
      $(this).addClass('active');
      searchAll();
      isNewsItemNon();
      if($('#search-year').val() === '') {
        setNewsMoreBtn();
      }
    }
  });
}

var searchBox = '.js-select li'; // 絞り込む項目を選択するエリア
var listItem = '.js-list-item';   // 絞り込み対象のアイテム
var hideClass = 'is-hide';     // 絞り込み対象外の場合に付与されるclass名
/**
 * リストの絞り込みを行う
 */
function search_filter() {

  // 選択されている項目を取得
  var name = $(searchBox).attr('name');
  var searchData = get_selected_items(name);
  // 選択されている項目がない、またはALLを選択している場合は飛ばす
  if(searchData[0] === 'all') {
    return false;
  }
  // リスト内の各アイテムをチェック
  for (var j = 0; j < $(listItem).length; j++) {
    // アイテムに設定している項目を取得
      
    var searchItem = $(listItem).eq(j).data('item');
    var searchItemArray = searchItem.split(',');

    for(var i = 0; i < searchData.length; i++) {
      if(!searchItemArray.includes(searchData[i])) {
        $(listItem).eq(j).addClass(hideClass);
        } else {
        $(listItem).eq(j).removeClass(hideClass);
        break;
      }
    }
  }
}

function get_selected_items(name) {
  var searchData = [];
  $('.js-select li.active').each(function() {
    searchData.push($(this).data('filter'));
  });
  return searchData;
}

function searchAll() {
  isHideRemove();
  $(listItem).removeClass(hideClass);
}

// 年絞り込み処理
function searchYear() {
  $('#search-year').on('change', function() {
    var selYear = $(this).val();
    var yearList = '.js-year-display';
    $(yearList).removeClass('is-hide');
    if(selYear === '') {
      if($('.js-select li.active').data('filter') === 'all') {
        setNewsMoreBtn();
      }
      isNewsItemNon();
      return false;
    }
    if($('.js-select li.active').data('filter') === 'all') {
      if($('.js-year-display:first .js-list-item').hasClass('is-hide')) {
        $('.js-list-item').removeClass('is-hide');
      }
    }
    for(var i = 0; i < $(yearList).length; i++) {
      var yearItem = $(yearList).eq(i).data('date');
      if(selYear.indexOf(yearItem) === -1) {
        $(yearList).eq(i).addClass('is-hide');
      }
      isNewsItemNon();
    }
    $('.js-more-btn-wrap').fadeOut();
  });
}

// もっと見るボタン表示処理
function setNewsMoreBtn() {
  var show = 19; // もっと見るボタンを表示させるアイテムの個数
  var yearList = '.js-year-display';
  var newsList = '.js-list-item'; // アイテム
  var newsLength = $(newsList).length; // 全アイテムの個数

  $('.js-more-btn-wrap').fadeIn();
  $(yearList).each(function() {
    var newsListLength = $(this).find(newsList).length; // 年代ごとのアイテムの個数

    // 各年でアイテムの個数が0の場合
    if(newsListLength === 0) {
      $(this).addClass('is-hide');
    } else {
      $(this).removeClass('is-hide');
    }
  });
  // 最新の年のアイテムが0の時
  if(newsLength >= show && $('.js-year-display:first').hasClass('is-hide')) {
    $('.js-more-btn-wrap').hide();
  // ニュースの個数が全体で19件以上の場合
  } else if(newsLength >= show) {
    $('.js-year-display:not(:first)').addClass('is-hide');
    // 最新年が19件以上ある場合
    if($('.js-year-display:first .js-list-item').length >= 19) {
      for(var i = 0; i < $('.js-year-display:first .js-list-item').length; i++) {
        if(i >= 18) {
          $('.js-year-display:first .js-list-item:nth-of-type(' + i + ')').addClass('is-hide');
        }
      }
    }
  } else {
    $('.js-more-btn-wrap').hide();
    isNewsItemNon();
  }
}

// もっと見るクリック時
function clickNewsMoreBtn() {
  var yearList = '.js-year-display';
  var newsList = '.js-list-item';
  $('.js-more-btn').on('click', function() {
    $(this).parents('.js-more-btn-wrap').fadeOut();
    $(yearList).each(function() {
      var newsListLength = $(this).find(newsList).length;
      var newsListNonLength = $(this).find(newsList + '.is-hide').length;
      if(newsListLength === newsListNonLength || newsListLength === 0) {
        $(this).addClass('is-hide');
      } else {
        $(this).removeClass('is-hide');
      }
    });
    if($(newsList).hasClass('is-hide')) {
      $(newsList).removeClass('is-hide');
    }
  });
}

// 年変更時の年タイトル表示処理
function isHideRemove() {
  var selYear = $('#search-year').val();
  var yearList = '.js-year-display';
  if(selYear === '') {
    $(yearList).removeClass('is-hide');
  } else {
    for(var i = 0; i < $(yearList).length; i++) {
      var yearItem = $(yearList).eq(i).data('date');
      if(selYear.indexOf(yearItem) === -1) {
        $(yearList).eq(i).addClass('is-hide');
      } else {
        $(yearList).eq(i).removeClass('is-hide');
      }
    }
  }
  $('.js-more-btn-wrap').fadeOut();
}

// 絞り込み時の表示ニュースが0件の時の年タイトル非表示処理
function isNewsItemNon() {
  var yearList = '.js-year-display';
  var newsList = '.js-list-item';
  $(yearList).each(function() {
    var newsListLength = $(this).find(newsList).length;
    var newsListNonLength = $(this).find(newsList + '.is-hide').length;
    if(newsListLength === newsListNonLength || newsListLength === 0) {
      $(this).addClass('is-hide');
    }
  });
}

function setRecruitSlider() {
  mySwiperRecruit = new Swiper('.swiper-recruit', {
    slidesPerView: 'auto',
    centeredSlides: true,
    initialSlide: 0,
    loop:true,
    spaceBetween: 20,
    breakpoints: {
      // 768px以上の場合
      768: {
        slidesPerView: 'auto',
        spaceBetween: 40,
        initialSlide: 1,
      },
      // 980px以上の場合
      980: {
        slidesPerView: 'auto',
        spaceBetween: 40,
        initialSlide: 1,
      }
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });
}